<template>
  <div class="saffron-app" :dir="isPageRtl ? 'rtl' : 'ltr'" v-bind="$attrs">
    <slot name="top"></slot>
    <!-- bar loader service -->
    <bar-loader
      v-if="useAsyncLoadBar"
      class="z-level-top-2"
      :active="asyncStatus.anyLoading"
      :fixed="true"></bar-loader>
    <!-- meta service -->
    <metainfo>
      <template #title="input">
        {{
          input.content
            ? input.content + " | " + input.metainfo.sitename.content
            : input.metainfo.sitename.content
        }}
      </template>
    </metainfo>

    <suspense>
      <div v-if="useLayouts" class="saffron-app-inner">
        <component
          :is="finalLayout"
          :key="finalLayout"
          :class="routerTransitionClassIn"
          v-bind="layoutParams">
          <slot>
            <router-view v-slot="{ Component }" :key="$route.href">
              <transition
                mode="out-in"
                :enter-active-class="routerTransitionClassIn"
                :leave-active-class="routerTransitionClassOut">
                <component :is="Component" v-if="Component" :key="$route.href" class="" />
              </transition>
            </router-view>
          </slot>
        </component>
      </div>
      <template #fallback="">
        <spinner :overlay="'fixed'"></spinner>
      </template>
    </suspense>
    <suspense>
      <div v-if="!useLayouts" class="saffron-app-inner">
        <slot>
          <router-view v-slot="{ Component }" :key="$route.fullPath">
            <transition
              mode="out-in"
              :enter-active-class="routerTransitionClassIn"
              :leave-active-class="routerTransitionClassOut">
              <component
                :is="Component"
                v-if="Component"
                :class="routerTransitionClassIn" />
            </transition>
          </router-view>
        </slot>
      </div>
      <template #fallback="">
        <spinner :overlay="'fixed'"></spinner>
      </template>
    </suspense>
    <slot name="bottom"></slot>
    <site-credits v-if="showSiteCredits" v-bind="siteCreditsProps"></site-credits>
  </div>

  <inactivity-disconnect></inactivity-disconnect>
  <!-- global spinner service -->
  <spinner
    v-if="useGlobalSpinner"
    class="z-level-top-2"
    overlay="fixed"
    :text-params="globalSpinnerTextParams"
    :show="globalSpinnerActive"
    :text="globalSpinnerText"></spinner>

  <teleport to="body">
    <modal
      ref="authErrorModal"
      :title="translate('core.user.clientSideAuthErrorTitle')"
      :show="showDisconnectedModal">
      <template #default>
        {{ translate("core.user.clientSideAuthErrorExplain") }}
      </template>
      <template #footer>
        <form-button @click="acceptDisconnect"
          >{{ translate("core.user.clientSideAuthErrorOk") }}
        </form-button>
      </template>
    </modal>

    <modal
      v-for="conf in modals"
      :key="conf.uid"
      :ref="`modal${conf.uid}`"
      :close-button="conf.type !== 'confirm'"
      :easy-close="conf.type !== 'confirm'"
      :show="true"
      :auto-translate="false"
      :title="conf.title"
      @modal:afterClose="
        modalStackCloseHandler({
          uid: conf.uid,
          actionType: $event.context || 'reject',
          data: conf.promptValue,
        })
      ">
      <template #default>
        <div class="" v-html="conf.content"></div>
        <div
          v-if="conf.content && conf.content !== '' && conf.type === 'prompt'"
          class="margin-l-bottom"></div>

        <div v-if="conf.type === 'prompt'" class="">
          <form-input v-bind="conf.options.field" v-model="conf.promptValue"></form-input>
        </div>
      </template>
      <!-- TODO: make confirm "lock" and not allow closing with screen click -->
      <template #footer="{ modal }">
        <div class="flex flex-end gap-m">
          <!-- case of - alert todo: handle type -->
          <form-button
            v-if="conf.type === 'confirm' || conf.type === 'prompt'"
            theme="gray-5"
            icon="x"
            @click="modal.close('reject')"
            >{{ conf.options.labels.cancel }}
          </form-button>
          <form-button
            theme="success"
            icon-end="chevron-inline-end"
            @click="modal.close('fulfil')">
            {{ conf.options.labels.ok }}
          </form-button>
        </div>
      </template>
    </modal>
  </teleport>
  <notification
    v-for="(conf, id) of notifications"
    v-bind="getSafeNotificationConfig(conf)"
    :key="id"
    @notification:afterClose="notificationClosedHandler(id)"></notification>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import layoutComposition from "@/client/extensions/composition/layout";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { useMeta } from "vue-meta";
import { mapGetters, useStore } from "vuex";
import { watchEffect, computed } from "vue";

export default {
  provide() {
    return {
      $saffronComponent: this,
      windowEvents: {
        on(event, callback) {
          if (utilities.isSSR()) {
            return this;
          }
          window.addEventListener(event, callback);
          return this;
        },
        off(event, callback) {
          if (utilities.isSSR()) {
            return this;
          }
          window.removeEventListener(event, callback);
        },
      },
      $cookie: this.$cookie,
    };
  },
  props: {
    useLayouts: {
      type: Boolean,
      default: true,
    },
    useAsyncLoadBar: {
      type: Boolean,
      default: true,
    },
    useGlobalSpinner: {
      type: Boolean,
      default: true,
    },
    useMeta: {
      type: Boolean,
      default: true,
    },
    showSiteCredits: {
      type: Boolean,
      default: false,
    },
    siteCreditsProps: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    let result = {};
    let store = useStore();

    let { asyncOps, asyncStatus } = asyncOperations();
    result = { ...result, asyncOps, asyncStatus };

    // meta handling
    if (props.useMeta) {
      let { t } = useI18n();
      let { meta } = useMeta({
        title: t("core.meta.defaultTitle"),
        //  htmlAttrs: { lang: 'en', amp: true }, // now working. fuck third party
        description: t("core.meta.defaultDescription"),
        sitename: {
          tag: "meta",
          name: "sitename",
          content: t("core.meta.siteName"),
        },
        'og:image': {
          tag: "meta",
          name: "og:image",
          content: '',
        },
        charset: { tag: "meta", charset: "utf8" },
        generator: { tag: "meta", content: "Saffron - spice for your code" },
        "theme-color": {
          tag: "meta",
          name: "theme-color",
          content: config.themeColor,
        },
      });

      // watch slug, and update meta accordingly
      watchEffect(() => {
        meta.sitename.content = t("core.meta.siteName");
        meta.title = t("core.meta.defaultTitle");
        meta.description = t("core.meta.defaultDescription");
      });

      result = { ...result, metaManager: meta };
    }

    if (props.useLayouts) {
      let { finalLayout, finalLayoutRaw, layoutParams } = layoutComposition(
        props,
        useRouter()
      );
      result = { ...result, finalLayout, finalLayoutRaw, layoutParams };
    }

    return result;
  },
  data: function () {
    return {
      isPageRtl: computed(() => {
        let val = this.isLanguageRtl();
        if (val && typeof val === "object" && typeof val.value !== "undefined") {
          return val.value;
        } else {
          return val;
        }
      }),
      shortLocaleTag: computed(() => {
        let val = this.getLocaleShort();
        if (val && typeof val === "object" && typeof val.value !== "undefined") {
          return val.value;
        } else {
          return val;
        }
      }),
      showDisconnectedModal: false,
      initialUserFetchPromise: this.$store.getters["user/initialFetchCompletePromise"],
      initialUserFetchComplete: this.$store.getters["user/initialFetchComplete"],
      modals: {},
      notifications: {},
      enableRouteTransitionAnimation: true,
      lastErrorInstance: false,
    };
  },
  computed: {
    routerTransitionClassIn() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassIn
        : "";
    },
    routerTransitionClassOut() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassOut
        : "";
    },
    layoutTransitionClassIn() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassIn
        : "";
    },
    layoutTransitionClassOut() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassOut
        : "";
    },
    ...mapGetters({
      globalSpinnerActive: "ui/isGlobalSpinnerActive",
      globalSpinnerText: "ui/globalSpinnerText",
      globalSpinnerTextParams: "ui/globalSpinnerTextParams",
      userAuthError: "user/authError",
    }),
  },
  watch: {
    userAuthError(newVal, oldVal) {
      // on auth error, show modal and logout the user. error is considered handled
      if (newVal && !oldVal) {
        this.$store.commit("user/setAuthError", false);
        this.$store.commit("user/logout");

        if (!config.saffronUser.newSessionReconnectSilentErrors) {
          this.showDisconnectedModal = true;
        }
      }
    },
    shortLocaleTag: {
      handler(newVal) {
        console.log("watch shortCode", newVal);
      },
      immediate: true,
    },
    isPageRtl: {
      handler(newVal) {
        let finalValue = newVal;
        if (typeof newVal === "object" && newVal && typeof newVal.value !== "undefined") {
          finalValue = newVal.value;
        }
        if (utilities.isSSR()) {
          return;
        }

        if (finalValue) {
          document.querySelector("html").setAttribute("dir", "rtl");
        } else {
          document.querySelector("html").setAttribute("dir", "ltr");
        }
      },
      immediate: true,
    },
    shortLocaleTag: {
      handler() {
        if (utilities.isSSR()) {
          return;
        }

        document.querySelector("html").setAttribute("lang", this.shortLocaleTag);
      },
      immediate: true,
    },
  },
  async created() {
    // provide self to saffron framework
    if (!this.$root.$saffronComponent) {
      this.$root.$saffronComponent = this;
    }

    this.setSaffronComponent(this);

    if (config.useDefaultSocketFunctionality && !utilities.isSSR()) {
      // listen to unexpected disconnects - refresh token revocations
      let socket = await this.asyncOps.socket.init();
      await socket.waitForUUID();

      socket.on("refreshTokensRevokedRemotely", (data) => {
        let ourToken = this.$store.getters["user/refreshToken"];
        if (data.deletedTokens.includes(ourToken)) {
          this.$s.ui.notification(
            "core.user.remoteRefreshTokenRevocationNotification",
            "warning"
          );
          this.$store.commit("user/logout");
          this.$router.push(config.user.saffronUser.defaultLogoutRedirect);
        }
      });
    }

    if (config.ecommerce.currency.useConversions) {
      // waiting is only required on ssr, and this is not done from this mehtod
      this.$store.dispatch("currency/fetchExchangeRates");
    }
  },
  updated() {},
  mounted() {

  },

  errorCaptured(error, instance, info) {
    console.log("before error", error, info);

    const name = instance.$options.name;

    const errorAsString = error ? error.toString() : "";
    const isRenderError = info === "render function";
    let errorInformationString = "";
    let errorStringName = name ? name : "unknown name";
    // make error information

    // should redirect: redirect if error is in page or is a render error
    errorInformationString += `Message: ${error?.message}<br>`;
    errorInformationString += `Hook information: ${info}<br>`;
    errorInformationString += `In: ${errorStringName}<br>`;
    warn("saffron cought an error", { error, instance, info });
    utilities.safeLog(error);

    if (config.env === "dev" || config.env === "development") {
      return false;
    }
    if (isRenderError) {
      this.redirectToErrorPage(errorInformationString, errorAsString);
    } else {
      this.informAboutError(error.message);
    }

    return false;
  },
  methods: {
    registerModal(conf) {
      // parse conf
      let uid = utilities.getUniqueNumber();

      this.modals[uid] = {
        uid: uid,
        type: conf.options.type || "alert",
        promptValue: conf.options.initialValue || null,
        ...conf,
      };
    },
    modalStackCloseHandler(arg) {
      let modalConf = this.modals[arg.uid];

      if (!modalConf) {
        return;
      }

      // try to fulfil or reject the modal promise, if available
      if (arg.actionType === "fulfil" && modalConf.fulfil) {
        modalConf.fulfil(arg.data);
      }

      if (arg.actionType === "reject" && modalConf.reject) {
        modalConf.reject();
      }

      delete this.modals[arg.uid];
    },
    registerNotification(options) {
      let id = utilities.getUniqueNumber();
      options.ref = "notification-" + id;
      this.notifications[id] = { ...options, id };
    },
    notificationClosedHandler(id) {
      if (!this.notifications[id]) {
        return;
      }

      if (
        this.notifications[id].fulfil &&
        typeof this.notifications[id].fulfil === "function"
      ) {
        this.notifications[id].fulfil();
      }

      delete this.notifications[id];
    },
    closeAllNotifications() {
      if (!this.notifications || typeof this.notifications !== "object") {
        return;
      }

      for (const conf of Object.values(this.notifications)) {
        // case of array ref
        if (
          conf.ref &&
          this.$refs[conf.ref] &&
          Array.isArray(this.$refs[conf.ref]) &&
          this.$refs[conf.ref].length === 1
        ) {
          this.$refs[conf.ref][0].close();
        }

        // case of "regular" ref. I do not know if this can actually happen, tbh.
       if (conf.ref && this.$refs[conf.ref] && !Array.isArray(this.$refs[conf.ref])) {
          this.$refs[conf.ref].close();
        }
      }
    },
    acceptDisconnect() {
      this.showDisconnectedModal = false;
      this.$router.go();
    },
    getSafeNotificationConfig(conf) {
      let result = { ...conf };

      ["id", "fulfil", "reject"].forEach((key) => {
        if (u.hasProperty(conf, key)) {
          delete result[key];
        }
      });
      return result;
    },
    redirectToErrorPage(errorInformation = false, errorAsString = false) {
      this.$router.push({
        name: "error",
        params: {
          technicalInformationHtml: errorInformation,
          errorAsString: errorAsString,
        },
      });
    },
    informAboutError() {
      this.$s.ui.notification("core.errorCatchNotification", "error");
    },
  },
  serverPrefetch() {
    let promises = [];

    let baseUserPromise = new Promise((resolve) => {
      // when we get user prefetch - release this hook
      watchEffect(() => {
        if (this.$store.getters["user/initialFetchComplete"]) {
          resolve();
        }
      });
    });
    let userPromise = utilities.promiseWithTimeLimit(baseUserPromise, 2500);
    promises.push(userPromise);

    if (config.ecommerce.currency.useConversions) {
      let baseExchangeRatesPromise = new Promise((resolve) => {
        watchEffect(() => {
          if (this.$store.getters["currency/areExchangeRatesLoaded"]) {
            resolve();
          }
        });
      });
      let exchangeRatesPromise = utilities.promiseWithTimeLimit(
        baseExchangeRatesPromise,
        1500
      );
      promises.push(exchangeRatesPromise);
    }

    return Promise.allSettled(promises).catch((e) => {});
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss">
#notifications {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  width: auto;
  height: 0;
  overflow: visible;
}

#snackbar {
  position: fixed;
  bottom: 0;
  inset-inline-start: 0;
  height: auto;
  width: auto;
  max-width: 700px;
  overflow: visible;
  padding-inline-start: clamp(
    10px,
    var(--margin-m),
    var(--margin-m)
  ); // support weUI with default fallback fallback

  @media screen and (max-width: 800px) {
    max-width: 90vw;
  }
}
</style>
